<template>
  <!-- Content -->
  <div>
    <b-spinner
      v-if="isLoading"
      class="center-x my-3"
      small
      variant="primary"
      type="grow"
    />
    <b-card v-if="item && isLoading === false" no-body>
      <b-dropdown
        id="dropdown-dropleft"
        variant="link"
        right
        class="dropdown-user"
        no-caret
      >
        <!-- edit things from element -->
        <template #button-content class="edit-button">
          <feather-icon
            v-if="canEdit"
            icon="Edit2Icon"
            size="20"
            class="text-primary edit-icon"
          />
        </template>
        <!-- Edit product -->
        <b-dropdown-text class="bg-light">
          <p class="user-name font-weight-bolder mb-0">
            {{ $t("edit-dropdown.title") }}
          </p>
        </b-dropdown-text>
        <b-dropdown-item 
          v-b-modal.modal-edit-product
        >
          {{ $t("edit-dropdown.details") }}
        </b-dropdown-item>

        <!-- Edit classifiers -->
        <b-dropdown-item
          v-if="canEdit"
          @click="isClassifiersModalVisible = true"
        >
          {{ $t("members.edit.classifiers.name") }}
        </b-dropdown-item>

        <!-- Edit custom fields -->
        <b-dropdown-item
          v-if="canEdit"
          @click="isCustomFieldsModalVisible = true"
        >
          {{ $t("members.edit.custom-fields.label") }}
        </b-dropdown-item>
      </b-dropdown>

      <!-- share element button -->
      <feather-icon
        v-if="canShare"
        v-b-tooltip.hover.bottom
        icon="Share2Icon"
        :title="$t('share.tooltip')"
        size="20"
        class="text-primary share-icon"
        @click="isShareModalVisible = true"
      />

      <!-- delete element button -->
      <feather-icon
        v-if="canDelete"
        icon="TrashIcon"
        size="18"
        class="text-primary delete-icon"
        role="button"
        @click="deleteItem(item.key)"
      />

      <b-card-body>
        <b-row class="my-2">
          <!-- LEFT: Product Image Container -->
          <b-col
            cols="12"
            md="5"
            class="align-items-center justify-content-center flex-column mb-2 mb-md-0"
          >
            <div class="align-items-center justify-content-center">
              <b-img
                :src="item.bgURL || ProductPlaceholder"
                :alt="`Image of ${item.name}`"
                class="product-img"
                style="border-radius: 1.2rem"
                fluid
              />
            </div>
            <div class="classifier-content mt-2">
              <!-- Custom fields -->
              <b-row
                v-for="(field, index) in item.custom"
                :key="index"
                class="mb-1"
              >
                <b-col v-if="field.value" class="font-weight-bold">
                  {{ translatedFieldName(field) }}
                </b-col>
                <b-col>
                  {{ getValue(field.value) }}
                </b-col>
              </b-row>
              <!-- Classifiers -->
              <hr v-if="item && item.classifiers.length > 0" class="mt-2">
              <template v-if="item && item.classifiers.length > 0">
                <div
                  v-for="(value, name) in sortedClassifiers"
                  :key="value.key"
                >
                  <div class="w-100">
                    {{ name }}:
                  </div>
                  <span v-for="v in value" :key="v.index">
                    <b-badge class="mb-50 mr-50" :style="classifierStyle(v)">
                      {{
                        translateTranslationTable(
                          $store.state.locale.currentLocale,
                          v.name
                        )
                      }}
                    </b-badge>
                  </span>
                </div>
              </template>
            </div>

            <!-- Locations -->
            <!--div
              v-if="item && item.locations"
              cols="12"
              class="px-lg-2 mt-lg-2 border-top pt-2 mb-2"
            >
              <h4 id="locations" class="text-dark mb-2 font-weight-600">
                {{ $t("community-others.locations") }}
              </h4>
              <b-row class="mb-2">
                <b-col>
                  <h6>{{ item.locations.locality }}</h6>
                  <p>{{ item.locations.address }}</p>
                </b-col>
              </b-row>
              <b-card no-body class="h-100 overflow-hidden" style="min-height: 360px">
                <places-map
                  :locations="locations"
                  :in-container="true"
                  style="height: 500px"
                />
              </b-card>
            </div-->
          </b-col>

          <!-- RIGHT: Product Details -->
          <b-col cols="12" md="7">
            <!-- Product Name -->
            <div class="d-flex justify-content-between">
              <div class="second-column mr-4">
                <h3>{{ item.name }}</h3>
                <b-card-text v-if="item.headline">
                  {{ item.headline }}
                </b-card-text>
                <!-- Product Owner -->
                <!-- <b-card-text
                  v-if="item.ownedByUser"
                  class="item-company mb-0 mt-1"
                >
                  {{ $t("products.created-by") }}
                  <span class="text-primary">{{ item.ownedByUser.name }}</span>
                </b-card-text> -->
                
                <!-- Price -->
                <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                  <h4
                    v-if="item.unitPrice && item.unitPrice > 0"
                    class="item-price mr-1"
                  >
                    {{ item.unitPrice }} {{ item.currency.ISO }}
                  </h4>
                </div>

                <!-- Buy Link -->
                <div 
                  v-if="item.buyLink" 
                  class="product-buy mb-1"
                >
                  <b-button
                    :href="item.buyLink"
                    target="_blank"
                    variant="primary"
                    class="my-50"
                  >
                    {{ $t("products.buy") }}
                  </b-button>
                </div>

                <!-- Product Description -->
                <b-card-text>
                  <!-- eslint-disable-next-line vue/no-v-html -->
                  <span class="content-container" v-html="item.description" />
                </b-card-text>
                <!-- <hr v-if="false">
                <div v-if="false" class="d-flex flex-column flex-sm-row pt-1">
                  <b-button
                    variant="outline-secondary"
                    class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                  >
                    <feather-icon icon="CalendarIcon" class="mr-50" />
                    <span>Meeting</span>
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  >
                    <feather-icon icon="MessageSquareIcon" class="mr-50" />
                    <span>Chat</span>
                  </b-button>
                </div>
                <hr v-if="false"> -->
                <div>
                  <h5 class="mb-1 mt-50">
                    {{ $t("products.contact.title") }}
                  </h5>
                  <b-button
                    variant="primary"
                    class="my-50"
                    @click="isContactModalVisible = true"
                  >
                    {{ $t("products.contact.action") }}
                  </b-button>
                </div>
              </div>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <classifiers-modal
        v-model="isClassifiersModalVisible"
        :item-key="item.key"
        morph-type="products"
        model-type="product"
        :classifiers="classifiers"
        @profileUpdated="fetchClassifiers"
      />
      <!-- sortedCustomFields -->
      <customfields-modal
        v-model="isCustomFieldsModalVisible"
        :item="item"
        morph-type="product"
        @profileUpdated="updateCustomFields"
      />
      <share-modal
        v-model="isShareModalVisible"
        :can-share="canShare"
        :item-name="item.name"
        model-type="product"
        :model-key="item.key"
        type="products"
      />

      <!-- Edit Product -->
      <b-modal
        id="modal-edit-product"
        :title="$t('projects.modal-edit-title')"
        cancel-variant="outline-secondary"
        :ok-title="$t('form.actions.save')"
        :cancel-title="$t('form-create-item.cancel')"
        centered
        size="lg"
        @ok="handleEditProduct"
      >
        <b-form>
          <b-row>
            <b-col cols="12">
              <b-form-group>
                <label for="name">{{ $t("form-create-item.title") }}:</label>
                <b-form-input
                  id="name"
                  v-model="itemInput.name"
                  type="text"
                  :state="formError"
                />
              </b-form-group>
            </b-col>
            <b-col cols="9">
              <b-form-group>
                <label for="price"> {{ $t("form-create-item.price") }}:</label>
                <b-form-input
                  id="price"
                  v-model="itemInput.unitPrice"
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-form-group>
                <label for="price"> {{ $t("form-create-item.coin") }}:</label>
                <b-form-select
                  v-model="itemInput.currency.ISO"
                  :filterable="true"
                  :options="currencyCo"
                  label="moneda"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group>
                <label class="mt-2" for="headline">{{
                  $t("form-create-item.headline")
                }}</label>
                <b-form-input v-model="itemInput.headline" type="text" />
              </b-form-group>
            </b-col>

            <!-- Buy link -->
            <b-col cols="12">
              <b-form-group>
                <label class="mt-2" for="buylink">{{
                  $t("form-create-item.buy-link")
                }}</label>
                <b-form-input
                  :placeholder="$t('products.create-placeholder.buy-link')"
                  v-model="itemInput.buyLink"
                  type="text"
                />
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group>
                <label
                  class="mt-2"
                  for="description"
                >{{ $t("form-create-item.description") }}:</label>
                <quill-editor v-model="itemInput.description" />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <p class="mt-3">
                {{ $t("form-create-item.image") }}:
              </p>
            </b-col>
            <b-col cols="12">
              <div class="border rounded p-2">
                <p class="mb-50">
                  {{ $t("form-create-item.image-select") }}
                </p>
                <b-media
                  no-body
                  vertical-align="center"
                  class="d-flex align-items-center flex-column flex-md-row"
                >
                  <b-media-aside>
                    <b-img
                      ref="refPreviewEl"
                      :src="imageSrc"
                      height="110"
                      width="170"
                      class="rounded mr-2 mb-1 mb-md-0"
                      style="background: #c0c0c0"
                    />
                  </b-media-aside>
                  <b-media-body>
                    <div class="d-inline-block d-flex align-items-center pl-2">
                      <b-form-file
                        v-model="imageChallenge"
                        class="pointer"
                        :accept="FileTypes"
                        :placeholder="$t('form-create-item.image-placeholder')"
                        :browse-text="$t('form-create-item.image-button')"
                      />
                    </div>
                  </b-media-body>
                </b-media>
              </div>
            </b-col>

            <!-- Locations -->
            <b-col cols="12">
                <b-form-group
                  :label="$t('organizations.edit.locations.label')"
                  label-for="profile-location"
                  class="font-weight-bold mt-3"
                >
                  <places-map-select
                    v-model="itemInput.locations"
                    :placeholder="$t('organizations.edit.locations.placeholder')"
                  />
                </b-form-group>
            </b-col>

          </b-row>
        </b-form>
      </b-modal>

      <!-- Contact Modal -->
      <b-modal
        v-model="isContactModalVisible"
        :title="$t('products.contact.modal.title')"
        hide-footer
      >
        <contact-modal
          model-type="product"
          :item="item.key"
          @close-modal="isContactModalVisible = false"
        />
      </b-modal>

      <!-- Delete/Unshare Modal -->
      <unshare-modal
        v-model="isUnshareModalVisible"
        model-type="product"
        @remove-item="handleRemoveItem"
      />
    </b-card>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue';
import { translateTranslationTable } from '@/@core/libs/i18n/utils';
import ProductPlaceholder from '@/assets/images/placeholders/light/placeholder-dark.jpeg';
import CustomfieldsModal from '@/@core/components/modal/CustomfieldsModal.vue';
import ClassifiersModal from '@/@core/components/modal/ClassifiersModal.vue';
import ShareModal from '@/@core/components/modal/ShareModal.vue';
import { quillEditor } from 'vue-quill-editor';
import { checkPermissions } from '@/@core/utils/roles-utils';
import ContactModal from '@/@core/components/modal/ContactModal.vue';
import Service from '@/config/service-identifiers';
import FileTypes from '@/@core/constants/FileTypes';
import UnshareModal from '@/@core/components/modal/UnshareModal.vue';
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue';
// import { ENABLED_APPS_GETTERS } from '@/store/enabled-apps/enabled-apps-store-constants';
// import { AppIDLUT } from '@copernicsw/community-common';
import { dateValidator } from '@/@core/utils/validations/validators';
import PlacesMapSelect from '@core/components/places-map-input/PlacesMapSelect.vue';
import PlacesMap from '@core/components/places-map-input/PlacesMap.vue';

export default {
  name: 'ProductDetails',
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    CustomfieldsModal,
    ClassifiersModal,
    ShareModal,
    quillEditor,
    ContactModal,
    UnshareModal,
    PlacesMapSelect,
    PlacesMap,
  },
  props: {
  },
  data() {
    return {
      isLoading: true,
      isClassifiersModalVisible: false,
      isCustomFieldsModalVisible: false,
      isShareModalVisible: false,
      imageChallenge: null,
      formError: null,
      imageSrc: null,
      isContactModalVisible: false,
      currencyCode: {},
      currencyCo: [],
      isUnshareModalVisible: false
      // lastLoadedPage: 1,
    };
  },
  computed: {
    isStaff() {
      return this.collective.isStaff;
    },
    FileTypes() {
      return FileTypes;
    },
    cFields() {
      return this.$store.getters.customFields;
    },
    isOwner() {
      return this.loggedUser?.id === this.item?.ownedByUser?.id;
    },
    id() {
      return this.$route.params.id;
    },
    item() {
      return this.$store.getters.item('products', this.id);
    },
    itemInput() {
      return this.$store.getters.item('products', this.id);
    },
    ProductPlaceholder() {
      return ProductPlaceholder;
    },
    classifiers() {
      return this.item?.classifiers ? this.item?.classifiers : [];
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    loggedUser() {
      return this.$store.getters.loggedUser;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canEdit() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || checkPermissions(
          'update',
          'product',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canDelete() {
      return (
        this.loggedUser.key === this.item?.ownedByUser?.key
        || checkPermissions(
          'delete',
          'product',
          this.loggedMemberRoles,
          this.$store.getters.currentCollective,
        )
      );
    },
    canShare() {
      return checkPermissions(
        'share',
        'product',
        this.loggedMemberRoles,
        this.$store.getters.currentCollective,
      );
    },
    sortedClassifiers() {
      const newObject = {};
      this.classifiers?.forEach((item) => {
        if (
          !newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ]
        ) {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              item.typeName,
            )
          ].push(item);
        }
      });
      return newObject;
    },
    appId() {
      return 3;
    },
    apps() {
      return this.$store.getters.apps;
    },
    app() {
      if (this.apps) {
        return this.apps.apps[this.appId] || {};
      }
      return {};
    },
  },

  // Fetch data when component is created!
  async created() {
    this.isLoading = true;
    await this.fetchData();
    this.updateBreadcrumbs();
    await this.fetchCode();
  },
  methods: {
    async fetchClassifiers() {
      await this.fetchData();
    },
    deleteItem() {
      this.isUnshareModalVisible = true;
    },
    async fetchCode() {
      const response = await this.$store.$service[Service.BackendClient].get(
        'currencies',
        {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            count: 164,
          },
        },
      );
      this.currencyCode = response.data.data;
      for (let i = 0; i < this.currencyCode.length; i++) {
        this.currencyCo.push(this.currencyCode[i].ISO);
      }
      return response;
    },
    // TODO: move to utils
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    // Call store action (with dispach) to load data from backend
    getImageSrc(url) {
      return getImageResource(url);
    },
    async handleEditProduct() {
      try {
        const result = await this.$store.dispatch('editItem', {
          item: {
            itemType: 'products',
            requestConfig: {
              key: this.id,
              productKey: this.id,
              name: this.itemInput.name,
              headline: this.itemInput.headline,
              description: this.itemInput.description,
              unitPrice: this.itemInput.unitPrice,
              currencyCode: this.itemInput.currency.ISO,
              locations: this.itemInput.locations,
              buyLink: this.itemInput.buyLink,
            },
          },
          file: this.imageChallenge,
        });
        this.item.bgURL = result.bgURL;
        this.itemInput = {};
        this.updateBreadcrumbs();
        this.fetchData();
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('success-message.general-success-edit'),
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        });
      } catch {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: this.$t('error-message.general-error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        });
      }
    },
    async handleRemoveItem(response) {
      const temo = {
        productKey: this.id,
      };
      if (response === true) {
        try {
          await this.$store.dispatch('unshareProject', {
            item: temo,
            type: 'Product',
            tableName: 'products',
          });
          this.$router.push({
            name: 'products',
            params: { communityId: this.$route.params.communityId },
          });
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.success', {
                item: this.$t('unshare.singular-items.product'),
              }),
              icon: 'CheckIcon',
              variant: 'success',
            },
          });
        } catch {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: this.$t('unshare.toast-messages.error', {
                item: this.$t('unshare.singular-items.product'),
              }),
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          });
        }
      }
    },
    translateTranslationTable,
    translatedFieldName(field) {
      let name = '';
      if (!this.cFields.unpaginated) {
        this.cFields.map((item) => {
          if (field.key === item.key) {
            name = item.name;
          }
        });
      }

      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        name,
      );
    },
    classifierStyle(classifier) {
      return {
        color: classifier.fontColor,
        'background-color': classifier.backgroundColor,
      };
    },
    async updateCustomFields(items) {
      await this.fetchData();
      if (typeof items === 'object') {
        const value = Object.values(items)[0];
        const key = Object.keys(items)[0];

        for (const row of this.item.custom) {
          if (row.key === key) {
            row.value = value;
          }
        }
      } else if (Array.isArray(items)) {
        for (const item of items) {
          const value = Object.values(item)[0];
          const key = Object.keys(item)[0];

          for (const row of this.item.custom) {
            if (row.key === key) {
              row.value = value;
            }
          }
        }
      }
    },
    async fetchData() {
      await this.$store.dispatch('getItems', {
        itemType: 'products',
        forceAPICall: true,
        productKey: this.id,
      });
      this.updateBreadcrumbs();
      this.isLoading = false;
    },
    getValue(value) {
      if (dateValidator(value)) {
        return value.split('-').reverse().join('-');
      }
      if (value === true) {
        return this.$t('yes');
      }
      if (value === false) {
        return this.$t('no');
      }
      return value;
    },
    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text:
            translateTranslationTable(
              this.$store.state.locale.currentLocale,
              this.app.customizationName
              ,
            ) || this.$t('products.title'),
          to: { name: 'products' },
        },
        {
          text: this.item?.name
            ? this.item.name
            : 'products.breadcrumb-text.product-details',
          active: true,
        },
      ];
      this.$store.commit('app/SET_BREADCRUMBS', breadcrumbs);
    },
  },
};
</script>
<style lang="scss">
.classifier-content {
  margin-left: 52px;
  width: 85%;
}
.content-container img {
  width: 100%;
}
.product-img {
  max-height: 500px;
}
.share-icon {
  position: absolute;
  top: -48px;
  right: 0;
  cursor: pointer;
}
.edit-icon {
  position: absolute;
  top: -48px;
  right: 60px;
  cursor: pointer;
}
.delete-icon {
  position: absolute;
  top: -48px;
  right: 30px;
  cursor: pointer;
}
.b-nav-dropdown .dropdown-toggle::after {
  display: none !important;
}
#dropdown-dropleft {
  list-style-type: none !important;
}
</style>
